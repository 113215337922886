import api from '../conexao.js';
// import { urlPreSignn } from '../fichapaciente/urlpresign.js';

export async function sendFile(numZap,desc,medWhatsApp,namePDF,bufferPDF){
        //BUSCA NO BANCO O REGISTRO DO ZAP
        var dados;
       
        // var linkURL = await urlPreSignn(namePDF)
        await api.get('/whatsapp',{params:{
                descricao: medWhatsApp
        }}).then((result) => {  dados = result.data
                                // console.log(result.data)
                         })
                .catch((error) => {alert('Celular não sincronizado com Whatsapp!')})
              
        
        // ENVIA A MSG


        if (dados){
                await api.post('https://chatbot.menuia.com/api/create-message', { 
                        authkey: dados.authkey,
                        appkey: dados.appkey,
                        message: namePDF,
                        to: '55' + numZap,
                        descricao: desc,
                        // file: linkURL
                        file: bufferPDF
                }).then((result) => {
                         alert( desc +' Enviado');
                        return(true)
                        
                
                }).catch((error) => { 
                        // alert('Receituário não enviada: '+ error.message)
                       alert('Erro ao Enviar: ' + error.message)
                        return(false)
                })  
        } 
}

export async function sendMsg(textoZap,numZap,descricao,medWhatsApp){
        //BUSCA NO BANCO O REGISTRO DO ZAP

         
        var dados;
        await api.get('/whatsapp',{params:{
                descricao: medWhatsApp
        }}).then((result) => { if (result.data) {dados = result.data}  
                                else { alert('Celular não sincronizado com Whatsapp!')}})
           .catch((error) => {alert('Celular não sincronizado com Whatsapp! ' + error.message)})

        // console.log(dados)

        // MANDA A MSG

        if (dados){
                await api.post('https://chatbot.menuia.com/api/create-message', { 
                        authkey: dados.authkey,
                        appkey: dados.appkey,
                        message: textoZap,
                        to: '55' + numZap
                }).then((result) => {
                        alert(descricao + ' Enviada');            
                }).catch((error) => { alert('Mensagem não enviada: ' + error)})  
        }
}



