import React from 'react';


export default function DashAvisos(){

    
    return (
    
        <>
        <center>
            <h5>Novo Recurso</h5>
            <td><h6>dia 04/12/24</h6></td>
            <tr>
            
                <td>
                    Agora contamos com a função WhatsApp, o Usuário consegue associar seu WhatsApp com o Sistema,
                    podendo assim enviar Confirmação de Consultas, Receitas, Pedidos de Exames e Atestados diretamente
                    para o WhatsApp do paciente. 
                </td>
            </tr>
            
        </center>

    </>  
   
    )
}

