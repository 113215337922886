// import novo from '../../img/novo.jpg'
// import { Canvas } from 'canvas';
// import rodape from '../../img/rodape.jpg'
// import { roundToNearestMinutes } from 'date-fns';


export default function ReceituarioControlado(ende,infos, nomeMed, nomePac, titlePag ,date,crmMed,propsCab,propsRod,send,bufferPDF){

const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var doc = {

    info: {
	        title: 'ReceituarioControlado '+ nomePac,
    },

    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [ 25, 25, 25, 25 ],
    


    content: [{
        // alignment: 'justify',
        table: {
            // margin: [5, 5, 5, 5],
            widths: ['*',5,'*'],
            body: [
                //TÍTULO
                [{alignment: 'center',bold: true, text:'Receituário Controlado'},{border: [false, false, false, false], text: ''}, {alignment: 'center',bold: true, text:'Receituário Controlado'}],
                // EMITENTE MEDICO ENDEREÇO
                [{table: {
                    widths: [ 'auto', 'auto'],
                    body: [
                        [{alignment: 'center', text:'Identificação do Emitente:'}, {colSpan: 1, rowSpan: 3, text: '\n1º via rentenção\n2º via paciente'}],
                        [{alignment: 'center', text: nomeMed+'\n'+crmMed}, ''],
                        [{alignment: 'center', text:ende},''],
                    ]
                }},
                {border: [false, false, false, false], text: ''},
                {table: {
                    widths: [ 'auto', 'auto'],
                    body: [
                        [{alignment: 'center', text:'Identificação do Emitente:'}, {colSpan: 1, rowSpan: 3, text: '\n1º via rentenção\n2º via paciente'}],
                        [{alignment: 'center', text: nomeMed+'\n'+crmMed}, ''],
                        [{alignment: 'center', text:ende},''],
                    ]
                }}],
                //PRESCRIÇÃO E PACIENTE
                ['Paciente: '+ nomePac + '\n\n' +
                'Prescrição: \n' + infos + '\n\n',  
                
                        {border: [false, false, false, false], text: ''},
                
                        'Paciente: '+ nomePac + '\n\n' +
                        'Prescrição: \n' + infos + '\n\n'],
                // DATA
                [{  layout: 'noBorders',
                    table: {
                    widths: ['50%','50%'],

                    body:[
                        [{alignment: 'left', text:'Data:     /     /    '},{alignment: 'right', text:'Carimbo e Assinatura do Médico'}],
                       

                    ]
                }}, 
                {border: [false, false, false, false], text: ''},
                //CARIMBO
                {   layout: 'noBorders',
                    table: {
                    widths: ['50%', '50%'],
                    body:[
                        [{alignment: 'left', text:'Data:     /     /     '},{alignment: 'right', text:'Carimbo e Assinatura do Médico'}],
                       

                    ]
                }}],
                // RODAPE
                [{  layout: 'noBorders',
                    table:{
                    widths: ['50%','50%'],
                    body:[
                        [{bold: true,alignment: 'center',  text:'Identificação do Comprador'},{bold: true,alignment: 'center', text:'Identificação do Fornecedor'}],
                        [{text: 'Nome:'},{text: ''}],
                        [{text: '   '},{text: '   '}],
                        [{text: 'Ident:'},{text: ''}],
                        [{text: 'Org. Emissor:'},{text: 'Assinatura do Farmacêutico'}],
                        [{text: 'Endereço:'},{text: ''}],
                        [{text: '   '},{text: '   '}],
                        [{text: 'Cidade:' },{text: ''}],
                        [{text: 'Telefone:(     )'},{text: 'Data:      /      /      '}],
                    ]
                }},
                {border: [false, false, false, false], text: ''},
                //OUTRO LADO RODAPE 
                    {   layout: 'noBorders',
                        table:{
                        widths: ['50%','50%'],
                        body:[
                            [{bold: true,alignment: 'center', text:'Identificação do Comprador'},{bold: true,alignment: 'center', text:'Identificação do Fornecedor'}],
                            [{text: 'Nome:'},{text: ''}],
                            [{text: '   '},{text: '   '}],
                            [{text: 'Ident:'},{text: ''}],
                            [{text: 'Org. Emissor:'},{text: ''}],
                            [{text: 'Endereço:'},{text: 'Assinatura do Farmacêutico'}],
                            [{text: '   '},{text: '   '}],
                            [{text: 'Cidade:' },{text: ''}],
                            [{text: 'Telefone:(     )'},{text: 'Data:      /      /      '}],
                        ]
                    }}
                ]
            ]
        }
    }
    ],    
  
}
return(doc)
}
// }

// if (send) {

//     var namePDF = 'RC'+nomePac.toLowerCase() + '.pdf';
    
//     console.log('RC'+nomePac.toLowerCase() + '.pdf')
//     pdfMake.createPdf(doc).getBuffer((buffer) => {

//             // COMANDO PARA ENVIAR PARA BUCKET
     
//             async function sobe()  {
//               const S3_BUCKET = "imgmedsolutions";
//               const REGION = "sa-east-1";
//               AWS.config.update({
//                 accessKeyId: "AKIAY3BB4HQM7BZWG7OA",
//                 secretAccessKey: "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",
//               });
//               const s3 = new AWS.S3({
//                 params: { Bucket: S3_BUCKET },
//                 region: REGION,
//               });
          
//               const params = {
//                 Bucket: S3_BUCKET,
//                 Key: namePDF,
//                 Body: buffer,
//                 Metadata: {
//                       ContentType: 'application/pdf',
//                   }
//               };
          
//               var upload = s3
//                 .putObject(params)
//                 .on("httpUploadProgress", (evt) => {
//                 })
//                 .promise();
          
//               await upload.then((err, data) => {
//                 console.log(err);
//                 // alert("File uploaded successfully.");
//               });
//               await upload.catch((err, data) => {
  
//                     //   alert("File uploaded Failed.");
//                     console.log(err.message)
//               });
//       };  

//       sobe()
//     })


// } else {pdfMake.createPdf(doc).download('ReceituarioControlado ' + nomePac + '.pdf');}



// }